<script setup lang="ts">
const { t, tm } = useI18n()
const route = useRoute()

const services = tm('about.milestones.services') as {
  icon: string
  value: number
  title: string
}[]

// show icon at index and hidden at other pages
const sectionIcon = computed(() => (route.path === '/' ? 'i-tabler-award' : ''))

// show headline at index and hidden at other pages
const sectionHeadline = computed(() =>
  route.path === '/' ? '' : t('about.milestones.headline')
)
</script>

<template>
  <KSection
    :icon="sectionIcon"
    :headline="sectionHeadline"
    :title="t('about.milestones.title')"
    :description="t('about.milestones.description')"
  >
    <template #body>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-6 sm:gap-8 lg:gap-16">
        <div
          v-for="(milestone, index) in services"
          :key="index"
          class="text-center"
        >
          <div class="flex justify-center">
            <UIcon :name="milestone.icon" class="w-12 h-12 text-primary-400" />
          </div>
          <div class="text-3xl sm:text-4xl md:text-5xl text-primary-400">
            <InspiraNumberTicker
              :value="milestone.value"
              class="py-2 font-bold"
            />
            <!-- <SparkBlurIn :word="milestone.value.toString()" /> -->
          </div>
          <div class="text-md text-lg text-neutral-500 dark:text-neutral-400">
            {{ milestone.title }}
          </div>
        </div>
        <div class="col-span-2 md:col-span-4 mt-4 sm:mt-0">
          <img
            src="/img/about/service-partners.webp"
            alt="Service Partners Logos"
            class="mx-auto rounded-xl w-full md:w-8/12"
            loading="lazy"
          />
        </div></div
    ></template>
  </KSection>
</template>

<style scoped></style>
